import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorBanner = makeShortcode("AuthorBanner");
const FeedSearch = makeShortcode("FeedSearch");
const Tags = makeShortcode("Tags");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBanner mdxType="AuthorBanner" />
    <p>{`Hi, I'm Manos :)`}</p>
    <p>{`I am Full Stack Software Engineer from Greece, currently focusing on Front End technologies. I usually speak JavaScript!`}</p>
    <p>{`I created this blog to document my development journey, share my thoughts and concerns about technology and self-development, while solidifying my everyday learning.`}</p>
    <FeedSearch mdxType="FeedSearch" />
    <Tags mdxType="Tags" />
    <FeedItems mdxType="FeedItems" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      